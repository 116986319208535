import { cva } from "class-variance-authority";
import { cn } from "utils/utils";
import { Label } from "./Label";

export const TagsVariants = cva("pb-2.5 pt-2 px-3 w-fit", {
  variants: {
    variant: {
      Light: "bg-green-0 text-green-600",
      Dark: "bg-green-600 text-white",
      White: "bg-gray-100 text-green-600",
    },

    type: {
      Rounded: "rounded-full",
      Square: "rounded-lg border-[1px] border-green-600",
    },
  },
  defaultVariants: {
    variant: "Dark",
    type: "Rounded",
  },
});

export const Tag = ({
  type,
  variant,
  className,
  children,
  // disabled,
  // hoverInteraction,
  onMouseEnter,
}: {
  type: "Rounded" | "Square";
  variant: "Light" | "Dark" | "White";
  className?: string;
  children: React.ReactNode;
  // disabled?: boolean;
  // hoverInteraction?: boolean;
  onMouseEnter?: () => void;
}) => {
  return (
    <Label
      onMouseEnter={onMouseEnter}
      size={"XS"}
      as="p"
      className={cn(
        TagsVariants({ variant, type }),
        {
          // 'opacity-40': disabled,
          // 'hover:opacity-100': hoverInteraction,
          // 'text-green-700 border-green-300': type === "Square" && variant === "Light",
        },
        className,
      )}
    >
      {children}
    </Label>
  );
};
